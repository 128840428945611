// Component Imports
import SiteContentInterface from "../../utils/structure/SiteContentInterface";
import ContentBlockModel from "../../utils/structure/ContentBlock/ContentBlockModel";
import Card from "../../components/card/Card";
import OpeningHours from "../../components/opening-hours/OpeningHoursElement";
import Gallery from "../../components/gallery/Gallery";
import Location from "../../components/location/Location";

// Import Text Content.
import sampleSection from "./text/sampleSection.md";

// Media Imports

import thaiMassageImage from "../images/thaimassage.jpeg";
import footMassageImage from "../images/footmassage.jpeg";
import aromatherapyImage from "../images/aromatherapy.jpeg";
import bodyScrubImage from "../images/wb_body_scrub.jpeg";
import deepTissueImage from "../images/wb_deep_tissue_massage.jpeg";
import thaiClassicImage from "../images/wb_thai_classic.jpeg";
import hotStoneImage from "../images/Hotstone1s-min.jpeg";
import hotStoneImage2 from "../images/Hotstone2s-min.jpg";
import hotStoneImage3 from "../images/Hotstone3s-min.jpg";
import giftCard from "../images/Giftcard.jpg";


import panoImage from "../images/pano.jpeg";
import gallery1 from "../images/gallery_1.jpeg";
import gallery2 from "../images/gallery_2.jpeg";
import gallery3 from "../images/gallery_3.jpeg";
import mapImage from "../images/location_map.png";

// Import Font Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faUser, faTimes, faInfo, faMapMarkedAlt, faCheck, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, } from '@fortawesome/free-regular-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import TextSection from "../../components/text-section/TextSection";
import DisplayableElement from "../../utils/structure/DisplayableElement";
import ContactLibrary from "../../components/common-contact/ContactLibrary";
import ContactType from "../../components/common-contact/ContactType";
import ContactEntry from "../../components/common-contact/ContactEntry";
import BlockTheme from "../../utils/structure/ContentBlock/BlockTheme";
import ContactForm from "../../components/contact-form/ContactForm";
import { useRef } from "react";


class SiteContent extends SiteContentInterface {
    constructor () {
        super();
        
        this.registerIconLibrary();
        this.populateApi();
        this.populateContact();
        this.populateHeader();
        this.populateFooter();
        this.addCustomContent();
    }

    // ==========================================================================================
    // Custom Content Content.
    // ==========================================================================================

    private addCustomContent = (): void => {

        const myRef = useRef(null);

        this.addHeroBlock(myRef);
        this.addBlockWithFeatures();
        this.addBlockWithCards();
        this.addBlockWithContactAndLocation(myRef);
        this.addBlockGallery();
    }

    private populateHeader = (): void => {
        this.setHeaderModel({
            title: "Hutt-Tha Thai Massage",
            subtitle: "Traditional Thai Massage in Perth",
            contactTypesToShow: [ContactType.PHONE, ContactType.EMAIL],
            theme: BlockTheme.FEATURE,
            blocks: []
        })
    }

    private populateFooter = (): void => {
        this.setFooterModel({
            height: 32,
            contactModel: this.getContactModel(),
            contactTextToShow: [ContactType.PHONE, ContactType.EMAIL],
            contactIconsToShow: [ContactType.FACEBOOK],
            theme: BlockTheme.INVERTED
        })
    }
    
    private addHeroBlock = (ref?: any): void => {
        const textElement = new TextSection({body: "Loading", markdownPath: sampleSection, centered: true, minHeight: 300, 
        callToActionLabel: "Book Now", callToActionLink: "www.google.com", callToActionRef: ref});
        const blockModel = this.addElementsAsNewBlock(textElement);

        blockModel
            .setImagePositionX("80%")
            .setBackgroundImage(panoImage)
            .setDarkenPercent(0.3)
            .setTheme(BlockTheme.INVERTED);
    }

    private withPrice(body: string, duration: string, price: string) {
        const priceTag = `\n\n###### **${price}** — ${duration}`;
        return body + priceTag;
    }

    private newMassageCard(title: string, body: string, image: string, prices: Array<Array<string>>) {
        body += "\n\n --- \n\n";
        for (let i: number = 0; i < prices.length; i++) {
            body = this.withPrice(body, prices[i][0], prices[i][1]);
        }

        const card = new Card({
            title: title, 
            body: body,
            imagePath: image,
        });

        return card;
    }

    private addBlockWithCards = (): void => {



        const card3 = this.newMassageCard(
            "Deep Tissue Massage",
            "Deep Tissue massages involve applying concentrated pressure to the inner layers of muscles and connective tissues to relieve deep muscle aches.",
            deepTissueImage,
            [["90 mins", "$135"],["60 mins", "$95"], ["30 mins", "$55"]]
        )

        const card4 = this.newMassageCard(
            "Reflexology (Foot Massage)",
            "Your feet work hard for you every day; regular foot massages improves circulation, stimulates muscles, reduces tension, and often eases pain. Treat your feet!",
            footMassageImage,
            [["90 mins", "$120"],["60 mins", "$80"], ["30 mins", "$50"]]
        )

        const card5 = this.newMassageCard(
            "Body Scrub with Aromatherapy",
            "Hutt-Tha Thai’s Body scrub will energize your body with a massage using exfoliating agents, leaving your skin feeling refreshed and absolutely radiating.",
            bodyScrubImage,
            [["90 mins", "$140"], ["60 mins", "$110"]]
        )

        const card6 = this.newMassageCard(
            "Hot Stone Massage",
            "Heated stones are placed onto the body whilst it is massaged. The heat penetrates into the deeper layer of tissue, without too much pressure. This thermotherapy is used to decrease stiffness, reduce inflammation, and increase blood flow.",
            hotStoneImage,
            [["90 mins", "$140"], ["60 mins", "$100"]]
        )

        const card7 = this.newMassageCard(
            "Hot Stone with Deep Tissue",
            "Hutt-Tha Thai’s signature hot stone massage, with Deep Tissue massages in tandem; the perfect combination to relieve deep muscle pains and aches.",
            hotStoneImage2,
            [["90 mins", "$140"], ["60 mins", "$100"]]
        )

        const card8 = this.newMassageCard(
            "Hot Stone with Aromatherapy",
            "Hutt-Tha Thai’s signature hot stone massage, with Aromatherapy massages in tandem; a relaxingly divine spa-like experience, and the perfect method to unwind and reward yourself.",
            hotStoneImage3,
            [["90 mins", "$135"], ["60 mins", "$100"]]
        )

        const card9 = this.newMassageCard(
            "Hutt-Tha Thai Massage - Gift Cards",
            "Gift Cards are now available! Want to show how much you appreciate your family, friends, and colleagues? We've got your back. Treat them to our fantastic massages. Give us a ring, or contact us through the form below, or pick them up in-store!",
            giftCard,
            []
        )

        const openingHours = new OpeningHours({
            showPublicHolidayMessage: true,
            slots: [
                {day: "Monday-Sunday (Open 7 Days)", detail: "10:30 AM to 8:00 PM", isOpen: true},
            ]
        });


        this.addElementsAsNewBlock(card3, card4, card5).setTheme(BlockTheme.BASIC);
        this.addElementsAsNewBlock(card6, card7, card8).setTheme(BlockTheme.BASIC);
        this.addElementsAsNewBlock(card9);
        this.addElementsAsNewBlock(openingHours);

    }

    private addBlockWithContactAndLocation = (ref: any): void => {

        const contactElement = new ContactForm({title: "Contact Us", contactFormApi: this.getEndpoint("contact"), 
        requireName: true, requireEmail: true, requirePhone: true, requireNotes: true});
        
        const mapLink: string | undefined = this.getContactModel().contactMap.get(ContactType.ADDRESS)?.link;
        const locationElement = new Location({
            title: "Our Location",
            mapAddress: mapLink ? mapLink : "",
            contactTypesToShow: [ContactType.ADDRESS, ContactType.PHONE, ContactType.EMAIL], 
            contactModel: this.getContactModel(),
            mapImage: mapImage});
            
        this.createNextBlock(BlockTheme.BASIC, ref)
        .add(contactElement)
        .add(locationElement);
    }

    private addBlockWithFeatures = (): void => {
  
        const card1 = this.newMassageCard(
            "Traditional Thai Massage",
            "Thai Massage is an authentic healing art, a unique form of body therapy developed as a therapeutic method of healing to maintain health and well-being.",
            thaiMassageImage,
            [["90 mins", "$130"],["60 mins", "$90"], ["30 mins", "$50"]]
        )

        const card8 = this.newMassageCard(
            "Thai Classic with Oil",
            "Hutt-Tha Thai's classic thai massage recalls traditional techniques of intensive and deep rhythmic pressing. Oil is incorporated to provide a luscious, relaxing experience.",
            thaiClassicImage,
            [["90 mins", "$130"],["60 mins", "$90"], ["30 mins", "$50"]]
        )

        const card2 = this.newMassageCard(
            "Aromatherapy",
            "Aromatherapy is massage therapy using aromatic massage oil or lotion that contains essential oils, creating a luxurious and soothing experience.",
            aromatherapyImage,
            [["90 mins", "$130"],["60 mins", "$90"], ["30 mins", "$50"]]
        )

        this.addElementsAsNewBlock(card1,card8, card2);
        
    }

    private addBlockGallery = (): void => {
        const galleryElement = new Gallery({
            images: [gallery1, gallery2, gallery3],
            imageColMd: 3
        });

        // this.addElementsAsNewBlock(galleryElement).setTheme(BlockTheme.FEATURE);
    }

    // ==========================================================================================
    // Common Content.
    // ==========================================================================================

    private populateContact = (): void => {

        const contactMap: Map<ContactType, ContactEntry> = new Map<ContactType, ContactEntry>();
        contactMap.set(ContactType.EMAIL, {body: "huttthai.massage@gmail.com", link: "mailto: huttthai.massage@gmail.com"});
        contactMap.set(ContactType.PHONE, {body: "08 9228 8641 - or - 0406 475 595", link: "tel: 0892288641",});
        contactMap.set(ContactType.FACEBOOK, {body: "Hutt-Tha Thai Facebook", link: "https://www.facebook.com/HutthaThaiPerth/"});
        contactMap.set(ContactType.ADDRESS, {
            body: "2/117 Brisbane Street, Northbridge <br> Perth WA 6000", 
            link: "2+117 Brisbane Street, Northbridge, Perth WA 6000"});

        this.setContactModel({
            name: "Hutt-Tha Thai Massage",
            contactMap: contactMap
        });
    }

    private populateApi = (): void => {
        this.setApiEndpoint("https://apix.huttthai.com.au/");
        this.setGoogleMapApiKey(undefined);
    }

    private registerIconLibrary = (): void => {

        library.add(faPhone, faEnvelope, faFacebookSquare, faUser, faTimes, faMapMarkedAlt);
        library.add(faInfo);
        library.add(faCheck);
        library.add(faEllipsisH);

        // Set the icons that we need for our contact library.
        const contactLibrary: ContactLibrary = ContactLibrary.getInstance();
        contactLibrary.setLabelAndIcon(ContactType.NAME, "Name", faUser);
        contactLibrary.setLabelAndIcon(ContactType.ADDRESS, "Address", faMapMarkedAlt);
        contactLibrary.setLabelAndIcon(ContactType.PHONE, "Phone", faPhone);
        contactLibrary.setLabelAndIcon(ContactType.EMAIL, "Email", faEnvelope);
        contactLibrary.setLabelAndIcon(ContactType.FACEBOOK, "Facebook", faFacebookSquare);
    }

    // ==========================================================================================
    // Utility.
    // ==========================================================================================

    private createNextBlock = (theme?: BlockTheme, ref?: any): ContentBlockModel => {
        const block = new ContentBlockModel(theme);
        block.ref = ref;
        this.getIndexPage().add(block);
        return block;
    }

    private addElementsAsNewBlock = (...elements: DisplayableElement[]): ContentBlockModel => {
        const block = this.createNextBlock();
        elements.forEach(e => block.add(e));
        return block;
    }
}

export default SiteContent

